<template>
  <div style="width:100%;height:100%;overflow-y:auto;padding:10px">
    <div class="text-center mb-1">
      <button class="btn btn-outline-primary px-4 py-1" v-if="addindex!=0" @click="addindex=0"> 添加 </button>
      <div v-else>
        <div class="btn-group" role="group" aria-label="First group">
          <template v-for="(i,typeindex) in itemTypes">
            <template v-if="i.value == 'Pano' || i.value == 'Image' || i.value == 'Video'">
              <button type="button" class="btn btn-outline-primary p-1" @click="addItemStart(addindex,i.value)">{{i.key}}</button>
            </template>
          </template>
        </div>
      </div>
    </div>
    <template v-for="(i,index) in vGroupItems">
      <div class="card" v-if="selectedIndex==index">
        <div class="card-header bg-primary py-1">
          <div class="card-title">
            {{getItemTypekey(i.$model.type)}}内容设置
          </div>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" @click="selectedIndex=null">
              <i class="fa fa-minus"></i>
            </button>
            <button type="button" class="btn btn-tool" @click="removeItem(index)">
              <i class="fas fa-times"></i>
            </button>
          </div>

        </div>
        <div class="card-body p-1">
          <div class="form-group has-feedback">
            <label for="Title">
              标题
            </label>
            <input type="text" class="form-control" :class="{ 'is-invalid': i.title.$error }" v-model.trim="i.$model.title" placeholder="请输入标题">
            <!--<span class="text-danger" v-if="!i.title.required">请输入标题</span>
            <span class="text-danger" v-else-if="!i.title.minLength">至少 {{i.title.$params.minLength.min}} 个字符</span>-->
            <span class="text-danger" v-if="!i.title.maxLength">最多 {{i.title.$params.maxLength.max}} 个字符</span>
            <!--<span class="text-danger" v-else-if="!i.title.isLink">格式不正确</span>-->
          </div>
          <div class="form-group">
            <label for="panoText">内容</label>
            <!--<textarea type="text" class="form-control" id="panoText" placeholder="输入内容" v-model:text="i.$model.description"></textarea>-->
            <PellEditor v-model="i.$model.description" :class="{'box-danger':!i.description.maxLength}" />
            <div class="text-right" :class="{'text-danger':!i.description.maxLength}">共{{i.$model.description.length}}/{{i.description.$params.maxLength.max}}个字符</div>
          </div>
          <div>
          </div>
          <!--<button type="button" class="btn btn-primary" @click="showFileLibDialog(index)">选择素材</button>-->
          <div v-if="i.$model.file&&i.$model.file.isImage" style="max-height:500px;max-width:600px">
            <LibImageCard :item="i.$model.file">
            </LibImageCard>
          </div>
          <div v-if="i.$model.file&&i.$model.file.isVideo" style="max-height:500px;max-width:600px">
            <LibVideoCard :item="i.$model.file">
            </LibVideoCard>
          </div>
          <div v-if="i.$model.file&&i.$model.file.isAudio" style="max-height:500px;max-width:600px">
            <audio :src="i.$model.file.url" controls="controls" controlslist="nodownload" ref="audioPlayer" style="height:30px;max-width:100%"></audio>
          </div>
          <!--<div class="info-box-icon bg-gray itemicon" v-if="i.$model.linkItem&&i.$model.linkItem.firstImage"><div class="imgdiv" :style="makeUrlStyle((i.$model.linkItem.firstImage||{url:''}).url)"></div></div>-->
          <div v-if="i.$model.linkItem" style="max-height:500px;max-width:100%;height:50vh">
            <Krpano ref="Pano" :pano-id="i.$model.linkItem.guid" height="100%" :krpanoSettings="krpanoSettings" :on-krpano-ready="setContextmenu" :sceneVars="sceneVars">
            </Krpano>
          </div>
          <div v-if="i.$model.type=='Link'" class="form-group has-feedback">
            <label for="url">链接</label>
            <input v-if="$inRole('管理员')||$inRole('VIP')" class="form-control" type="url" v-model.trim="i.$model.url" placeholder="请输入 Url 地址" />
            <input v-else class="form-control" value="需要开通 VIP" disabled />

            <!--<span class="text-danger" v-if="!i.url.required">请输入 Url 地址</span>
            <span class="text-danger" v-else-if="!i.url.minLength">至少 {{i.link.$params.minLength.min}} 个字符</span>-->
            <span class="text-danger" v-if="!i.url.maxLength">最多 {{i.link.$params.maxLength.max}} 个字符</span>
            <span class="text-danger" v-else-if="!i.url.isUrl">格式不正确</span>
          </div>
          <div v-if="i.$model.type=='Location'" style="height:120px;margin-bottom:15px;position:relative">
            经度:{{i.$model.gpsLng}} 纬度:{{i.$model.gpsLat}}
            <div v-if="i.$model.gpsLng!=0||i.$model.gpsLat!=0">
              <MapView :mark="{lat:i.$model.gpsLat,lng:i.$model.gpsLng}"></MapView>
            </div>
          </div>
          <div v-if="i.$model.type=='Coordinate'&&i.$model.file" style="max-height:500px;max-width:600px">
            <MapView :geojsonUrl="i.$model.file.url" :dragable="true" height="400px"></MapView>
          </div>
        </div>
      </div>
      <div class="card" v-else>
        <div class="card-header py-1" :class="{ 'bg-danger': i.$invalid, 'bg-gray': !i.$invalid }">
          <div class="card-title text-truncate" style="max-width: calc(100% - 60px);">
            {{i.$model.title&&i.$model.title!=''?i.$model.title:'无标题'}}&nbsp;
          </div>
          <div class="card-tools">
            <button type="button" class="btn btn-tool" @click="selectedIndex=index" title="展开">
              <i class="fa fa-plus"></i>
            </button>
            <button type="button" class="btn btn-tool" @click="$refs.deleteItem.show=true;deleteIndex=index" title="删除">
              <i class="fas fa-times"></i>
            </button>

          </div>
        </div>
        <!-- /.card-header -->
        <div class="card-body p-0" @click.stop="selectedIndex=index" style="cursor:pointer">
          <div style="display:flex;justify-content:space-around;align-items:flex-start">
            <div class="itemicon" style="flex:none;margin:5px;align-self:center;color:#fff">
              <div v-if="i.$model.type=='Pano'" class="imgdiv" :style="makeUrlStyle((((i.$model.linkItem||{}).firstImage||{url:''}).url||'').replace('/0/0/0/0/', '/200/0/0/0/'))">
                <i class="icon-panorama drop-shadow"></i>
              </div>
              <div v-if="i.$model.type=='Image'" class="imgdiv" :style="makeUrlStyle(i.$model.file.url.replace('/0/0/0/0/', '/200/0/0/0/'))">
                <i class="fas fa-image text-shadow"></i>
              </div>
              <div v-if="i.$model.type=='Text'" class="imgdiv">
                <i class="fas fa-align-justify text-shadow"></i>
              </div>
              <div v-if="i.$model.type=='Audio'" class="imgdiv">
                <i class="fas fa-music text-shadow"></i>
              </div>
              <div v-if="i.$model.type=='Video'" class="imgdiv" :style="makeUrlStyle(i.$model.file.url.replace('/0/0/0/0/', '/200/0/0/0/'))">
                <i class="fas fa-video text-shadow"></i>
              </div>
              <div v-if="i.$model.type=='Link'" class="imgdiv">
                <i class="fas fa-link text-shadow"></i>
              </div>
              <div v-if="i.$model.type=='Location'" class="imgdiv">
                <i class="fas fa-map-marked-alt text-shadow"></i>
              </div>
              <div v-if="i.$model.type=='Coordinate'" class="imgdiv">
                <i class="fas fa-route text-shadow"></i>
              </div>
            </div>
            <div style="flex:auto;">
              <template v-if="i.$model.type!='Text'">
                <div v-html="i.$model.description?i.$model.description:'无内容'" style="margin-top: 5px; height: 50px; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; word-break: break-all; ">
                </div>
                <div style="height: 25px; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; word-break: break-all; ">
                  <template v-if="i.$model.type=='Image'||i.$model.type=='Audio'||i.$model.type=='Video'||i.$model.type=='Coordinate'">
                    {{i.$model.file.name}}
                  </template>
                  <template v-else-if="i.$model.type=='Location'">
                    经度:{{i.$model.gpsLng.toFixed(4)}} 纬度:{{i.$model.gpsLat.toFixed(4)}}
                  </template>
                  <template v-else-if="i.$model.type=='Link'">
                    {{i.$model.url}}
                  </template>
                </div>
              </template>
              <div v-else v-html="i.$model.description?i.$model.description:'无内容'" style="flex: auto; margin-top: 5px; max-height: 75px; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; word-break: break-all;">
              </div>
            </div>
            <div style="flex:none;margin:5px;align-self:center">
              <div class="btn-group-vertical" role="group" aria-label="First group">
                <button type="button" class="btn btn-outline-primary" @click.stop="inGroupMove(i.$model,-1)">上移</button>
                <button type="button" class="btn btn-outline-primary" @click.stop="inGroupMove(i.$model,1)">下移</button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.card-body -->
      </div>
      <div class="text-center mb-1">
        <button class="btn btn-outline-primary px-4 py-1" v-if="addindex!=+index+1" @click="addindex=+index+1"> 添加 </button>
        <div v-else>
          <div class="btn-group" role="group" aria-label="First group">
            <button type="button" class="btn btn-outline-primary p-1" v-for="(i,typeindex) in itemTypes" @click="addItemStart(addindex,i.value)">{{i.key}}</button>
          </div>
        </div>
      </div>
    </template>
    <MapSelectedPointModal ref="newLocation" @submit="creatLocation">
    </MapSelectedPointModal>
    <template v-if="urls">
      <FileLib ref="FileMultipleLib" :multiple="true" :libUrl="urls.fileLib()" :panosUploadUrl="urls.panosUploadUrl()" :filesUploadUrl="urls.filesUploadUrl()" :tagsUrl="urls.privateTags()" :fileUrl="urls.fileUrl()" :panoUrl="urls.panoUrl()" :params="params" :withType="FileMultipleLibType" :onSelected="FileLibSelected" @submit="FileMultipleLibOnSubmit"></FileLib>
      <PanoLib ref="PanoMultipleLib" :multiple="true" :libUrl="urls.libPanosUrl()" :params="params" @submit="PanoMultipleLibOnSubmit"></PanoLib>
    </template>
  </div>
</template>
<script>
  import Krpano from '../../Krpano'
  import MapView from '../../Map/MapView'
  import PellEditor from '../../PellEditor'
  import FileLib from '../../FileLib'
  import PanoLib from '../../PanoLib'
  import LibImageCard from '../../LibImageCard'
  import LibVideoCard from '../../LibVideoCard'
  import MapSelectedPointModal from '../../Map/MapSelectedPointModal'

  export default {
    components: {
      Krpano,
      MapView,
      PellEditor,
      FileLib,
      PanoLib,
      LibImageCard,
      LibVideoCard,
      MapSelectedPointModal,
    },
    props: {
    },
    data() {
      return {
        addindex: null,
        selectedIndex: null,
        itemTypes: [
          { key: '全景', value: 'Pano' },
          { key: '图片', value: 'Image' },
          { key: '文本', value: 'Text' },
          { key: '音频', value: 'Audio' },
          { key: '视频', value: 'Video' },
          { key: '链接', value: 'Link' },
          { key: '定位', value: 'Location' },
          { key: '航迹', value: 'Coordinate' }
        ],
        krpanoSettings: {
          mwheel: false,
          //passQueryParameters: true
          vars: {
            'preview.type': 'grid(CUBE,64,64,512,0x000000,0x000000,0x000000);',
            'autorotate.enabled': true,
            'autorotate.speed': 7,
            'autorotate.accel': 1,
            'autorotate.waittime': 5
          }
        },
        sceneVars: {
          'view.keep': true,
          keep: true,
        },
        FileMultipleLibOnSubmit: () => { },
        PanoMultipleLibOnSubmit: () => { },
        FileMultipleLibType: null,
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getComonent: {
        default: {}
      },
      getFunc: {
      }
    },
    watch: {
    },
    computed: {
      urls() {
        return this.publicData.urls
      },
      params() {
        return this.publicData.params
      },
      $v() {
        return this.publicData.$v || {}
      },
      form() {
        return this.publicData.form || {}
      },
      groupGuid() {
        return this.publicData.groupGuid
      },
      vGroupItems() {
        var gg = this.groupGuid
        var g = []
        var items = this.$v.form.items.$each.$iter
        for (var i in items) {
          var item = items[i]
          if (item.$model.groupGUID == gg) {
            g.push(item)
          }
        }
        return g
      },
      groupItems() {
        return this.publicData.groupItems || []
      },
      editItemGuid() {
        return this.publicData.editItemGuid
      },
      editItemIndex() {
        for (var i in this.form.items) {
          if (this.form.items[i].guid == this.editItemGuid) {
            return i
          }
        }
        return null
      },
      editItem() {
        if (this.editItemIndex === null) {
          return {}
        }
        return this.form.items[this.editItemIndex]
      },
      senceGuid() {
        return this.publicData.senceGuid
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
          addItem: {
            target: 'Data',
            name: 'addItem',
          },
        },
        funcs: {
        }
      })
    },
    destroyed() {
    },
    methods: {
      setContextmenu(krpano) {
        var menuXml = `
<krpano>
<contextmenu fullscreen="false" versioninfo="true">
    <item name="fs" caption="全屏浏览"     onclick="set(fullscreen,true);"      showif="fullscreen == false" />
    <item name="ef" caption="退出全屏"     onclick="set(fullscreen,false);"     showif="fullscreen == true" />
    <item name="cc" caption="切换控制模式" onclick="skin_changecontrolmode();"  separator="true" />
    <item name="nv" caption="一般视角"     onclick="skin_view_normal();"        showif="view.vlookatrange == 180" separator="true"      />
    <item name="fv" caption="鱼眼视角"     onclick="skin_view_fisheye();"       showif="view.vlookatrange == 180" devices="flash|webgl" />
    <item name="sv" caption="立体视角"     onclick="skin_view_stereographic();" showif="view.vlookatrange == 180" devices="flash|webgl" />
    <item name="av" caption="构架视角"     onclick="skin_view_architectural();" showif="view.vlookatrange == 180"                       />
    <item name="pv" caption="超广角视角"   onclick="skin_view_pannini();"       showif="view.vlookatrange == 180" devices="flash|webgl" />
    <item name="lp" caption="小行星视角"   onclick="skin_view_littleplanet();"  showif="view.vlookatrange == 180" devices="flash|webgl" />
  </contextmenu>


  <action name="skin_changecontrolmode">
    switch(control.mouse, moveto, drag);
    switch(control.touch, moveto, drag);
  </action>

  <action name="skin_view_look_straight">
    if(view.vlookat LT -80 OR view.vlookat GT +80,
      tween(view.vlookat, 0.0, 1.0, easeInOutSine);
      tween(view.fov,     100, distance(150,0.8));
      );
  </action>

  <action name="skin_view_normal">
    skin_view_look_straight();
    tween(view.architectural, 0.0, distance(1.0,0.5));
    tween(view.pannini,       0.0, distance(1.0,0.5));
    tween(view.distortion,    0.0, distance(1.0,0.5));
  </action>

  <action name="skin_view_fisheye">
    skin_view_look_straight();
    tween(view.architectural, 0.0,  distance(1.0,0.5));
    tween(view.pannini,       0.0,  distance(1.0,0.5));
    tween(view.distortion,    0.35, distance(1.0,0.5));
  </action>

  <action name="skin_view_architectural">
    skin_view_look_straight();
    tween(view.architectural, 1.0, distance(1.0,0.5));
    tween(view.pannini,       0.0, distance(1.0,0.5));
    tween(view.distortion,    0.0, distance(1.0,0.5));
  </action>

  <action name="skin_view_stereographic">
    skin_view_look_straight();
    tween(view.architectural, 0.0, distance(1.0,0.5));
    tween(view.pannini,       0.0, distance(1.0,0.5));
    tween(view.distortion,    1.0, distance(1.0,0.8));
  </action>

  <action name="skin_view_pannini">
    skin_view_look_straight();
    tween(view.architectural, 0.0, distance(1.0,0.5));
    tween(view.pannini,       1.0, distance(1.0,0.8));
    if(view.distortion LT 0.1,
      tween(view.distortion, 1.0, distance(1.0,0.8));
      );
  </action>

  <action name="skin_view_littleplanet">
    tween(view.architectural, 0.0, distance(1.0,0.5));
    tween(view.pannini,       0.0, distance(1.0,0.5));
    tween(view.distortion,    1.0, distance(1.0,0.8));
    tween(view.fov,           150, distance(150,0.8));
    tween(view.vlookat,        90, distance(100,0.8));
    add(new_hlookat, view.hlookat, 123.0);
    tween(view.hlookat, get(new_hlookat), distance(100,0.8));
  </action>
</krpano>
`
        krpano.call(`loadxml(${menuXml},null,MERGE|KEEPSCENES,null)`)
      },
      getItemTypekey(value) {
        for (var i in this.itemTypes) {
          if (this.itemTypes[i].value == value)
            return this.itemTypes[i].key
        }
      },
      getIndexByGuid(guid) {
        for (var i in this.form.items) {
          if (this.form.items[i].guid == guid) {
            return i
          }
        }
        return null
      },
      addItemStart(index, type) {
        if (index == 0) {
          if (!(type == 'Pano' || type == 'Image' || type == 'Video')) {
            this.$message({
              message: '第一项不能是无图片项',
              type: 'info',
              zIndex: 9999,
            })
            return;
          }
        }
        if (type == 'Pano') {
          var p = new Promise((resolve, reject) => {
            this.PanoMultipleLibOnSubmit = (files) => {
              resolve(files)
            }
            setTimeout(() => {
              this.$refs.PanoMultipleLib.showDialog();
            }, 1)
          }).then((files) => {
            console.log(files);
            for (var i in files) {
              this.addItem(index + parseInt(i), type, files[i])
            }
            this.PanoMultipleLibOnSubmit = () => { };
            this.addindex = null
          })
          return;
        }
        if (type == 'Location') {
          this.$refs.newLocation.showModal()
          return
        }
        if (type == 'Image' || type == 'File' || type == 'Audio' || type == 'Video' || type == 'Coordinate') {
          var p = new Promise((resolve, reject) => {
            this.FileMultipleLibOnSubmit = (files) => {
              resolve(files)
            }
            this.FileMultipleLibType = type
            setTimeout(() => {
              this.$refs.FileMultipleLib.showDialog();
            }, 1)
          }).then((files) => {
            console.log(files);
            for (var i in files) {
              this.addItem(index + parseInt(i), type, files[i])
            }
            this.FileMultipleLibType = null;
            this.FileMultipleLibOnSubmit = () => { };
            this.addindex = null
          })
        } else {
          this.addItem(index, type, null)
          this.addindex = null
          if (type == 'Text' || type == 'Link') {
            this.selectedIndex = index
          }
        }
      },
      creatLocation(p) {
        if (p.lat == 0 && p.lng == 0) {
          return
        }
        var item = this.addItem(+this.addindex, 'Location', null, { gpsLat: p.lat, gpsLng: p.lng })
        console.log('creatLocation', item)
        //this.form.items[this.addindex].gpsLat = p.lat
        //this.form.items[this.addindex].gpsLng = p.lng
        //  item.gpsLat = p.lat
        //  item.gpsLng = p.lng
      },
      showFileLibDialog(index) {
        this.selectedIndex = index;
        this.$refs.FileMultipleLib.showDialog();
      },
      FileLibSelected(select) {
        this.form.items[this.selectedIndex].file = select;
        this.selectedIndex = null;
      },
      addItem(index, type, file, datas) {
        if (this.form.items.length >= this.$v.form.items.$params.maxLength.max) {
          this.$message({
            message: `最多只能添加 ${this.$v.form.items.$params.maxLength.max} 项`,
            type: 'info',
            zIndex: 9999,
          })
          return
        }
        var item = { ...datas }
        item.type = type
        if (type == 'Pano') {
          item.linkItem = file;
          item.title = file.title
        } else {
          item.file = file;
        }
        if (file && file.name) {
          item.title = file.name
        }
        console.log(index);
        var itemIndex = 0
        if (this.groupItems[index]) {
          var guid = this.groupItems[index].guid
          itemIndex = this.getIndexByGuid(guid)
        }
        this.$emit('addItem', { index: itemIndex, groupGuid: this.groupGuid, item, })

        this.selectedIndex = index;
        return item
      },
      inGroupMove(item, relative) {
        var ps = this.getInGroupIndexs(item.groupGUID)
        var from = this.getIndex(item.guid)
        var psi = 0
        for (var i = 0; i < ps.length; i++) {
          if (ps[i] == from) {
            psi = i
            break
          }
        }
        var targeti = psi + Number(relative)
        if (targeti >= 0 && targeti < ps.length) {
          this.moveItem(from, ps[targeti])
        } else {
          this.$message({
            message: '移动错误2',
            type: 'error',
            zIndex: 9999,
          })
          return
        }
      },
      moveItem(from, to) {
        var item = this.form.items[from]
        if (to < 0 || to > this.form.items.length || item == null) {
          this.$message({
            message: '移动错误',
            type: 'error',
            zIndex: 9999,
          })
          return
        }
        this.form.items.splice(from, 1)
        this.form.items.splice(to, 0, item)
        var groupItems = this.getFunc({ target: 'Data', name: 'getGroupItems' })()
        this.getFunc('changePublicData')({ groupItems })
      },
      removeItem(index) {
        var itemIndex = this.getIndexByGuid(this.groupItems[index].guid)
        this.form.items.splice(itemIndex, 1)
      },
      getInGroupIndexs(groupGuid) {
        var indexs = []
        for (var i = 0; i < this.form.items.length; i++) {
          if (this.form.items[i].groupGUID == groupGuid) {
            indexs.push(i)
          }
        }
        return indexs
      },
      getIndex(guid) {
        for (var i in this.form.items) {
          if (this.form.items[i].guid == guid) {
            return i
          }
        }
        return false
      },
      makeUrlStyle(url) {
        return {
          'background-image': "url('" + url + "')"
        }
      },
    },
  }
</script>
<style scoped>
  .box-danger {
    border: 1px solid #dc3545;
  }

  .itemicon {
    width: 70px;
    height: 70px;
    background-color: gray;
    border-radius: 4px;
    overflow: hidden;
  }

    .itemicon > .imgdiv {
      width: 100%;
      height: 100%;
      background-image: url('');
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
    }
</style>
